<template>
    <div id="app">
        <loading v-if="qrTokenLoading"></loading>
        <div class="version">{{currentVersionBuildTime}}</div>
        <Navbar
            :user="user"
            :notDeliveredCnt="notDelivered?(parseInt(notDelivered.notDeliveredCnt) + parseInt(notDelivered.egaisNotAccepted)):null"
            @logout="logout"
            class="no-print"
            />
        <error-warning :errors="eError" :warning="warning" @closeError="closeError" @closeWarning="warning=null"></error-warning>
        <div v-if="user && user.isAnonymous && !($route.matched.some(({name}) => name === 'auth' || name === 'priceAvto' || name === 'price'))"
                class="container"
                style="margin-top: 3rem;">
            <div class="notification is-info">
                <div class="icon-text" style="align-items: center;">
                    <span class="icon" style="width: 35px; height: auto; vertical-align: middle; margin-right: 15px;">
                        <img src="@/assets/login_white_24dp.svg" style="width: 100%;">
                    </span>
                    <span style="font-size: 24px;">
                        Для продолжения работы <router-link to="/auth" style="color:#ffeb61">выполните вход</router-link>
                    </span>
                </div>
                <div style="margin-top: 2rem;">
                    При возникновении вопросов, обращайтесь по номеру телефона <span style="white-spance: nobr;">+7 900 077 53-01</span> (Илья)<br><br>
                </div>
            </div>
        </div>
        <router-view
            :user="user"
            :scannedBarcode="scannedBarcode"
            @logout="logout"
            @error="error"
            @print="print"
            @clearScannedBarcode="scannedBarcode=null"
            @connectScanner="connectScanner(true)"
            @setbarcode="optionsSetBarcode"
            @connectScales="manualConnectScales"
            :scales="scales"
            :isScannerConnected="serialScanner!==null"
            :isScalesConnected="isScalesConnected"
            :inputWeight="inputWeight"
            :isWeightSedated="isWeightSedated"
            @newUser="user=$event"
            v-if="!user || (user && user.localback === false)"
            >
        </router-view>
        <div class="modal modal-fx-fadeInScale" :class="{'is-active': hashChanged}" v-if="hashChanged && $root.env !== 'development'">
            <div class="modal-background"></div>
            <div class="modal-content" style="background: #fff; padding: 2em;">
                <p>Доступно обновление. Сохраните текущие изменения и нажмите кнопку снизу.</p>
                <p class="text-center" style="margin: 1rem 0;">Не принятие обновления может повлечь ошибки в работе сайта</p>
                <div class="refresh-button text-center">
                    <button class="button is-primary" @click="reloadApp">Обновить</button>
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="closeRefreshDialog"></button>
        </div>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import Navbar from '@/components/Navbar'
    import {refreshPageMixin} from './refresh-page.mixin.js'
    import {scannerMixin} from './scannerMixin.js'
    import {commonMixin} from './commonMixin.js'
    import userGql from "./common"
    import ErrorWarning from './components/ErrorWarning.vue'
    import ScalesMixin from './components/ScalesMixin'
    import _ from 'lodash'

    export default {
        name: 'root',
        components: {
            Navbar, ErrorWarning, 
        },
        mixins: [
            refreshPageMixin, scannerMixin, commonMixin, ScalesMixin,
        ],
        mounted(){
            /*console.log("Created userId: ",this.$router.currentRoute.query.userId)
            if (!this.$router.currentRoute.query.userId){
                console.log("push query: ",this.user)
                this.$router.push({ name: "index", query: {userId: 5}, })
            }*/
            this.initVersionCheck("/version.json", 'index');
        },
        data(){
            return {
                itemModal: false,
                item: null,
                search: '',
                eError: Array(),
                warning: null,
                waitingWeight: false,
            }
        },
        methods: {
            optionsSetBarcode(barcode) {
                this.scannedBarcode = barcode;
            },
            closeRefreshDialog(){
                this.hashChanged=false;
            },
            error(err){
                if (err===null)
                    return;
                if (err == 'Error: Network error: Unexpected token \'P\', "Proxy erro"... is not valid JSON')
                    err = 'Ошибка сервера. Проблемой занимаются. Попробуйте выполнить запрос позже...'
                else {
                    if (err.toString().includes('Network error: Failed to fetch'))
                        err = 'Ошибка сети. Проверьте соединение с интернетом.'
                }
                if (!this.eError.find(e=>_.isEqual(e, err))){
                    console.log(err);
                    this.eError.push(err);
                }
            },
            closeError(idx){
                this.eError.splice(idx, 1);
            },
            /*user_refetch(){
                this.$apollo.queries.user.refetch();
                //this.$apollo.queries.cartSummary.refetch();
            },
            group_select(item){
                this.page=1;
                this.search="";
                this.tu.count=0;
                this.tu.items.splice(0);
                this.branch_item=item;
            },
            reloadSummary(){
                //this.$apollo.queries.cartSummary.refetch();
            },

            modcart(item, caller){
                console.log("MODCART", item);
                //let itemlist=this.$refs.catalog?this.$refs.catalog.$refs.tulist.$refs['itemlist-'+item.id][0]:this.$refs.cart.$refs.cartlist.$refs['cartlist-'+item.objectId][0];
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!, $newvalue: Decimal!, $quantityType: Int!) {
                        setcart(productId: $id, newvalue: $newvalue, quantityType: $quantityType) { ok }
                    }`,
                    variables: {
                        "id": item.id?item.id:item.objectId,
                        "newvalue": item.quantity,
                        "quantityType": item.quantityType,
                    },
                }).then(data => {
                    caller.modcartfinished();
                    //this.$apollo.queries.cartSummary.refetch();
                    this.error(null);
                }).catch(error => {
                    caller.modcartfinished(true);
                    this.error(error);
                });
            }
            */
        },
        apollo: {
            user: {
                query: gql(`
                    query{
                        ` + userGql + `
                    }`),
                pollInterval: 5 * 60 * 1000, // ms
                result({data, loading}) { // eslint-disable-line no-unused-vars
                    if (data && data.user && data.user.id){
                        if (process.env.NODE_ENV=='production'){
                            window.ym(83894410, 'userParams', {
                                UserID: parseInt(data.user.id)
                            });
                        }
                        /*console.log(this.$router)
                        if (!this.$router.currentRoute.query.userId || this.$router.currentRoute.query.userId!=this.user.id){
                            console.log("push query: ",this.user)
                            this.$router.push({ name: this.$router.currentRoute.name, query: {userId: this.user.id}, })
                        }*/
                    }
                },
                errorPolicy: 'all',
                error(e) {
                    this.error(JSON.stringify(e.message));
                },
            },
            notDelivered: {
                query: gql`
                    query{
                        notDelivered {
                            notDeliveredCnt
                            egaisNotAccepted
                        }
                    }`,
                errorPolicy: 'all',
                skip(){
                    return !this.user || this.user.isAnonymous;
                },
                error(e) {
                    this.warning = e.message;
                },
            },
        },
    }
</script>


<style>
    #app {
        /*overflow-x: hidden;*/
    }
    #app:focus{
        outline: none;
        border: 0;
    }        
    .my-error, .my-warning{
        margin-top: 1rem;
    }
    .version{
        position: fixed;
        bottom: 0;
        right: 0;
    }
</style>

