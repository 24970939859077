<script>
    import gql from "graphql-tag"
    export default {
        name: 'CartMixin',
        methods: {
            getCartName(){
                return 'CART-ID';
            },
            modcart(item, caller, quantityType=0){
                if (process.env.NODE_ENV=='production')
                    ym(83894410,'reachGoal','add-to-cart'); // eslint-disable-line
                this.$apollo.mutate({
                    mutation: gql`mutation($cartname: String!, $id: ID!, $newvalue: Decimal!, $quantityType: ID) {
                        setcart(cartname: $cartname, productId: $id, newvalue: $newvalue, quantityType: $quantityType) { ok updatedValue}
                    }`,
                    variables: {
                        "id": item.id?item.id:item.objectId,
                        "newvalue": item.quantity,
                        "quantityType": quantityType, //item.quantityType,
                        "cartname": this.getCartName(),
                    },
                }).then(({data, loading}) => { // eslint-disable-line no-unused-vars
                    caller.modcartfinished(data);
                    if (this.$apollo.queries.cartSummary)
                        this.$apollo.queries.cartSummary.refetch();
                    if (item.quantity==0 && this.$apollo.queries.cart){
                        this.$apollo.queries.cart.refetch();
                    }
                    this.error=null;
                }).catch(error => {
                    this.$emit('error', error);
                    caller.modcartfinished(true);
                });
            },
        },
        apollo: {
            cartSummary: {
                query: gql`query($cartname: String!) {cartSummary(cartname: $cartname) {id sum cnt}}`,
                variables() {
                    return {
                        "cartname": this.getCartName(),
                    }
                },
                errorPolicy: 'all',
                error(error) {
                    this.$emit('error', error);
                },
            },
        },
	}
</script>
