<template>
    <section class="container" v-if="checkGroup(user, 'Ревизия')">
        <h1 class="title" style="margin-bottom: 3px;">Ревизия</h1>
        <h3 v-if="audit && audit.sklad">Склад: {{audit.sklad.name}}. Начало {{audit.created|datetime}} 
            <span>{{audit.info}}</span>
        </h3>
        
        
        <audit-menu :item='1' :auditId='auditId' :disable="mutation">
        </audit-menu>
        <div class="columns" style="margin-top: 0rem;">
            <div class="column">
                <div class="field searchfield">
                    <div class="control">
                        <input placeholder="Найти" class="input" type="text" v-model="search" v-on:input="debounceInput"
                            :disabled="mutation">
                    </div>
                </div>
                <div style="position: relative;" :class="{'loading': $apollo.queries.auditTu.loading}">
                    <form autocomplete="off">
                    <table v-if="auditTu" class="report striped search-results" style="width: 100%;" :class="{'mut': mutation}">
                        <tr>
                            <th>Код</th>
                            <th>Наименование</th>
                            <!--th>Релев-сть</th--->
                            <th>Учет</th>
                            <th>Факт</th>
                            <th>Добавить</th>
                        </tr>
                        <tr v-for="item in auditTu" class="hover" :key="item.tu.id" :class="{'succ': succ_id==item.tu.id}">
                            <td>{{item.tu.nomer}}</td>
                            <td>
                                {{item.tu.name}}
                                <div class="price">
                                    Цена: {{item.tu.price|currency-null2}}
                                    ШК: <span v-if="item.tu.tuPtr && item.tu.tuPtr.barcodes">
                                            <span v-for="b in item.tu.tuPtr.barcodes">{{ b.barcode }} </span>
                                        </span>
                                </div>
                            </td>
                            <!--td>{{item.searchScore}}</td-->
                            <td>{{item.acount|number-null03}}</td>
                            <td>
                                <currency-input 
                                    v-model="item.valX"
                                    @change="change($event, item)"
                                    :disabled="mutation"
                                    autocomplete="one-time-code"
                                    class="input input-fact" id='inputfact'
                                    :options="{ currency: 'EUR', 
                                                currencyDisplay: 'hidden', 
                                                precision: item.tu.isWeight?3:0,
                                                nullOnClean: true
                                            }"
                                    style="text-align: right;"/> 
                            </td>
                            <td>
                                <currency-input 
                                    v-model="item.addValue"
                                    @change="addChange($event, item)"
                                    :disabled="mutation"
                                    autocomplete="one-time-code"
                                    class="input input-add" id='input-add'
                                    :options="{ currency: 'EUR', 
                                                currencyDisplay: 'hidden', 
                                                precision: item.tu.isWeight?3:0,
                                                nullOnClean: true
                                            }"
                                    style="text-align: right;"/> 
                            </td>
                        </tr>
                    </table>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import _ from 'lodash'
    import gql from "graphql-tag"
    import AuditMenu from './AuditMenu.vue'

    export default {
        name: 'Audit',
        components: {
            AuditMenu,
        },
        emits: ["clearScannedBarcode"],
        props: {
            user: Object,
            auditId: String,
            q: String,
            scannedBarcode: String,
        },
        mounted() {
            this.search_debounced = this.q || ''
            this.search = this.search_debounced
        },
        watch: {
            scannedBarcode: function (val) {
                if (val){
                    this.tu=[];
                    this.search_debounced=val;
                    this.search=val;
                    this.push()
                    this.$emit('clearScannedBarcode')
                }
            },
        },
        data(){
            return {
                search: '',
                search_debounced: '',
                sending: false,
                item: null,
                fact: null,
                xxx: 'start',
                test: null,
                mutation: false,
                succ_id: null,
            }
        },
        methods: {
            debounceInput: _.debounce(function (e) {
                this.tu=[];
                this.search_debounced=e.target.value;
                this.push()
            }, 500),
            push(){
                this.$router.push(
                    {
                        name: 'Audit',
                        query: {
                            q: this.search_debounced,
                        }
                    });
            },
            onEscape(){
            },
            addChange(value, item){
                if (value==null || value==0)
                    return
                if (item.fact==null){
                    item.valX=value
                    item.addValue=null
                    this.change(value,item)
                } else {
                    item.valX+=value
                    item.addValue=null
                    this.change(item.valX,item)
                }
            },
            change(value, item){
                if (value==item.fact)
                    return;
                this.mutation = true;
                if (process.env.NODE_ENV=='production')
                    window.ym(83894410, 'reachGoal', 'audit-set-fact', 
                              {auditId: this.auditId, tuId: item.tu.id, amount: value});
                this.$apollo.mutate({
                    mutation: gql`mutation($auditId: ID!, $tuId: ID!, $amount: Decimal) {
                        auditSetFact(auditId: $auditId, tuId: $tuId, amount: $amount) { ok }
                    }`,
                    variables: {
                        auditId: this.auditId,
                        tuId: item.tu.id,
                        amount: value, //parseInt(this.fact),
                    },
                }).then(data => {
                    item.fact=value;
                    this.mutation = false;
                    this.succ_id=item.tu.id;
                    setTimeout(() => {
                        console.log('time out');
                        this.succ_id=null;
                    }, 300); 
                }).catch(error => {
                    this.$emit('error', JSON.stringify(error.message));
                    this.mutation = false;
                    //this.$apollo.queries.auditFacts.refetch();
                });
            },
            /*factAdded(fact){
                if (this.item.fact)
                    this.item.fact+=fact;
                else
                    this.item.fact=fact;
            },*/
        },
        apollo: {
            audit: {
                query: gql`
                    query($id: ID!){
                        audit(id: $id){
                            id created info 
                            sklad{
                              id name
                            }
                            relative
                        }
                    }`,
                variables() {
                    return {
                        id: this.auditId,
                        }
                },
                errorPolicy: 'all',
                result ({ data, loading }) {
                    if (!loading) {
                        //this.fill();
                    }
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
            auditTu: {
                query: gql`
                    query($search: String!, $auditId: ID!) {
                        auditTu(search: $search, auditId: $auditId) {
                            acount fact valX:fact tu {
                                id name nomer inbox price isWeight
                                tuPtr{barcodes{barcode}}
                            }
                        }
                    }`,
                variables() {
                    return {
                        search: this.search_debounced,
                        auditId: this.auditId,
                    }
                },
                result({data, loading}){
                    if (!loading){
                        return data.auditTu.map(e=>{
                            e.valX = e.fact;
                            e.addValue = null;
                            return e
                        });

                    }
                    //lastimg.scrollIntoView({behavior: "smooth"});
                    /*window.scrollTo(
                        {
                            top: 56,
                            behavior: "smooth"
                        }
                    );*/
                },
                error(error) {
                    this.$emit('error', JSON.stringify(error.message));
                },
            },
        },
    }
</script>

<style lang="scss" scoped>
    .hover {
        cursor: pointer;
    }
    .searchfield {
        position: sticky;
        top: 62px;
        z-index: 10;
    }
    .search-results{
        td:nth-child(3), td:nth-child(4), td:nth-child(5){
            text-align: right;
        }
        td{
            vertical-align: middle;
        }
    }
    .input-fact{
        width: 80px;
    }
    .input-add{
        width: 80px;
    }
    .mut{
        color: #a0a0a0;
    }
    .succ {
        background-color: rgb(85, 253, 161) !important;
    }
    .price {
        font-size:85%;
    }
</style>
