<template>
    <div class="container" v-if="checkGroups(user, ['Заказы', 'Продавец СБИС', 'Продавец', 'Оптовый клиент'])">
        <section class="section">
            <div class="columns" v-if="!sended">
                <div class="column is-two-fifths">
                    <h1 class="title">Заявка</h1>
                    <div class="field">
                        <v-select :options="[{code:null, label: '-'}].concat(agents)" v-if="agents" v-model="agent"></v-select>
                    </div>


                    <!--div class="field">
                        <div class="select host">
                            <select v-model="host_id">
                                <option :value="host.id" v-for="host in hosts">{{host.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="field">
                        <div class="select osnr">
                            <select v-model="osnr_id">
                                <option :value="x.id" v-for="x in osnr">{{x.name}}</option>
                            </select>
                        </div>
                    </div-->


                    <!--div class="field">
                        <date-picker 
                            v-model="deliverydate" 
                            class="deliverydate"
                            valueType="format" 
                            :first-day-of-week="1" 
                            lang="ru" 
                            format="DD-MM-YYYY" 
                            :not-before="new Date()" 
                            placeholder="Дата доставки"
                            :not-after="(new Date()).setDate((new Date()).getDate() + 6)"></date-picker>
                    </div-->
                    <div class="field">
                        Дата: {{nextDay()}}
                    </div>
                    <div class="field">
                        <label class="label">Примечание:</label>
                        <div class="control">
                            <textarea class="descr input" v-model="txt"></textarea>
                        </div>
                    </div>
                    <button class="button is-primary" @click="save" :disabled="sending" :class="{'is-loading': sending}">
                        Сохранить и отправить
                    </button>

                    <div class="notification is-danger" v-if="error">
                        {{error}}
                    </div>

                </div>
                <div class="column is-three-fifths">
                    <h1 class="title">Корзина <span v-if="type=='kdv'" v-once>КДВ</span></h1>
                    <p style="margin-bottom: 1rem;" v-if="cartSummary">
                        Кол-во: {{cartSummary.cnt}}, Сумма: {{cartSummary.sum|currency}}
                    </p>
                    <CartList 
                        :cart="cart" 
                        :loading="$apollo.queries.cart.loading"
                        ref="cartlist"
                        @modcart="modcart"
                        @remove="remove"
                        />
                </div>
            </div>
            <TitleWithCheck v-if="sended">
                <div class="title-text">Заявка отправлена.</div>
                <p class="sub-title">
                    <router-link to="/store">
                        Перейти к каталогу
                    </router-link><br>

                    <router-link to="/history">
                        История заказов.
                    </router-link>
                </p>
            </TitleWithCheck>
        </section>
    </div>
</template>

<script>

    import gql from "graphql-tag"
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/locale/ru';
    import 'vue2-datepicker/index.css'
    import {format, addDays} from 'date-fns'
    import CartList from './CartList'
    import CartMixin from './CartMixin'
    import TitleWithCheck from '../TitleWithCheck.vue'

    export default {
        name: 'Cart',
        components: {
            DatePicker, CartList, TitleWithCheck,
        },
        mixins: [CartMixin],
        props: {
            user: Object,
            type: String,
        },
        created(){
            this.$apollo.queries.cart.refetch();
        },
        methods: {
            nextDay(){
                return format(addDays(new Date(),1),'dd-MM-yyyy')
            },
            getCartName(){
                if (this.type == 'kdv')
                    return 'KDV-CART-ID'
                return 'CART-ID';
            },
            remove(item){
                this.$apollo.mutate({
                    mutation: gql`mutation($id: ID!, $cartname: String) {
                        removefromcart(productId: $id, cartname: $cartname) { ok }
                    }`,
                    variables: {
                        id: item.objectId,
                        cartname: this.getCartName(),
                    },
                }).then(data => { 
                    /*itemlist.modcartfinished();

                    if (typeof item.product !== 'undefined')
                        item.quantity = item.product.weight?data.data.modcart.newquantity:Math.round(data.data.modcart.newquantity);
                    else
                        item.quantity = item.isWeight?data.data.modcart.newquantity:Math.round(data.data.modcart.newquantity);
                    */
                    if (data.data.removefromcart.ok){
                        
                        for (var i = this.cart.length - 1; i >= 0; --i) {
                            if (this.cart[i].objectId==item.objectId){
                                this.cart.splice(i,1);
                            }
                        }

                        this.$apollo.queries.cartSummary.refetch();
                    }
                }).catch(error => { // eslint-disable-line no-unused-vars
                    //itemlist.modcartfinished(true);
                    //console.log(error);
                });
            },
            save(){
                this.sending = true;
                let ymClientId=null;
                if (process.env.NODE_ENV=='production'){
                    window.ym(83894410, 'getClientID', function(cID){ymClientId = cID;});                    
                    ym(83894410,'reachGoal','order'); // eslint-disable-line
                }
                this.$apollo.mutate({
                    mutation: gql`
                        mutation($hostId: Int!, $osnrId: Int!, $txt: String!, $date: String!, $agentId: ID!, $cartname: String!, $orderType: String, $clientId: String) {
                        order(hostId: $hostId, osnrId: $osnrId, txt: $txt, date: $date, agentId: $agentId, cartname: $cartname, orderType: $orderType, clientId: $clientId) 
                            { ok }
                        }`,
                    variables: { 
                        hostId: 3, //this.host_id, 
                        osnrId: this.agent.code=='408'?5:4, // для контрагента Кирса склад - основание - перемещение
                        txt: this.txt,
                        date: this.nextDay(), // this.deliverydate,
                        agentId: this.agent.code,
                        cartname: this.getCartName(),
                        orderType: this.type=='kdv' ? 'kdv': null,
                        clientId: ymClientId,
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    //console.log(this.$apollo);
                    this.$apollo.queries.cart.refetch();
                    this.$apollo.queries.cartSummary.refetch();
                    this.txt='';
                    this.sended=true;
                    this.error=null;
                    this.sending = false;
                }).catch(error => {
                    //card.add2cart_finish ed();
                    //console.log(error);
                    this.error=error;
                    this.sending = false;
                });
            },
        },
        data(){
            return {
                tu: null,
                deliverydate: format((new Date()).setDate((new Date()).getDate() + 1),'dd-MM-yyyy'),
                host_id: 1,
                agent: null,
                hosts: [],
                osnr_id: 3,
                osnr: [],
                txt: "",
                sended: false,
                sending: false,
                error: null,
            }
        },
        apollo: {
            cart: {
                query: gql`
                    query($cartname: String!){
                        cart(cartname: $cartname) {
                            quantity unitPrice unitPrice2 objectId
                            product{
                                id name weight packOrderOnly inbox
                                images {id img}
                            }
                            quantityType inbox
                        }
                    }`,
                variables() {
                    return {
                        "cartname": this.getCartName(),
                    }
                },
                result({data, loading}){
                    return data.cart.map(e=>{
                            e.valX = e.quantity;
                            return e
                        });
                },
            },
            /*hosts: {
                query: gql`
                    query {
                        hosts {id name}
                    }`,
            },
            osnr: {
                query: gql`
                    query {
                        osnr {id name}
                    }`,
            },*/
            agents: {
                query: gql`query{agents {code:id label:name}}`,
                result(data) {
                    if (data && data.data && data.data.agents && data.data.agents.length){
                        this.agent = data.data.agents[0];
                    }
                },
                //skip(){
                //    return !this.user;
                //},
            },
        },
	}
</script>

<style lang="scss" scoped>
	.descr {
		width: 90%;
		min-height: 80px;
	}
</style>

<style lang="scss">
    .mx-table-date td, .mx-table-date th {
        vertical-align: middle !important;
        text-align: center !important;
    }
</style>
