<template>
    <div class="container" v-if="user && !user.isAnonymous && user.isStaff">
        <section class="section">
            Прогноз закупок
            <div class="loading" v-if="$apollo.queries.prediction.loading"></div>
            <ul v-if="prediction && prediction.agents">
                <li
                    v-for="agent in prediction.agents"
                    :key="agent.id">
                    <h2 v-if="sklad">Склад: {{sklad.name}}</h2>
                    <h2>Поставщик: {{agent.name}}</h2>
                    <div style="overflow-x: auto; margin-bottom: 3rem;">
                        <table class="products striped">
                            <tr>
                                <th>Номер</th>
                                <th>Наименование</th>
                                <th>Цена</th>
                                <th>Кол-во</th>
                                <th>Сумма</th>
                                <th>Остаток</th>
                                <th>В упак</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr :key="product.id" v-for="(product, index) in agent.products" class="hover">
                                <td>{{product.nomer}}</td>
                                <td>{{product.name}}</td>
                                <td style="text-align: right;">{{product.incomeCena|currency}}</td>
                                <td style="text-align: right;">
                                    {{Math.ceil(values[product.id])}}
                                </td>
                                <td style="text-align: right;">
                                    {{values[product.id]*product.incomeCena*product.inbox|currency}}
                                </td>
                                <td style="text-align: right;">{{product.balance}}</td>
                                <td style="text-align: right;">{{product.inbox}}</td>
                                <td>
                                    <input type="number" v-model="values[product.id]" class="input"> уп.
                                </td>
                                <td><a @click="remove(index)">Убрать</a></td>
                            </tr>
                        </table>
                        <div v-if="agent && agent.products">Итого: {{itogo(agent.products)|currency}}</div>
                    </div>
                    <button @click="download" class="button is-primary" style="margin-right: 1rem;">Скачать в pdf</button>
                    <button @click="openpdf" class="button is-primary">Открыть в pdf</button>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import axios from 'axios'
    export default {
        name: 'ReportPredictionAgent',
        props: {
            user: Object,
            sklad_id: String,
            agent_id: String,
            type: String,
        },
        data: () => {
            return {
                values: {},
            }
        },
        methods: {
            remove(index){
                this.prediction.agents[0].products.splice(index,1);
            },
            itogo(x){
                let sum=0;
                x.forEach(e => {
                    sum += e.incomeCena*this.values[e.id]*e.inbox;
                });
                return sum;
            },
            download(){
                axios.post(
                    '/reports/prediction1',
                    {
                        agents: this.prediction.agents[0],
                        values: this.values
                    },
                    {
                        responseType: 'blob'
                    }
                ).then(response => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'prediction.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    }
                ).catch(error => this.uploadError=error);
            },
            openpdf(){
                /*console.log(JSON.parse(JSON.stringify(this.values)));
                axios({
                    method: 'post',
                    url: 'http://localhost:8015/reports/prediction1',
                    data: {
                        //agents: JSON.parse(JSON.stringify(this.prediction.agents[0])),
                        //values: JSON.parse(JSON.stringify(this.values)),
                        x: 1
                    },
                    transformRequest: [(data, headers) => {
                        return data;
                    }]
                });

                const formData = new FormData();
                formData.append('data', JSON.stringify({
                    //agents: JSON.parse(JSON.stringify(this.prediction.agents[0])),
                    values: JSON.parse(JSON.stringify(this.values)),
                }));*/


                axios.post(
                    '/reports/prediction1',
                    {
                        agents: this.prediction.agents[0],
                        values: this.values
                    },
                    {
                        //'headers': {'Authorization': 'JWT ' + this.$store.state.token},
                        //'Content-Type': 'multipart/form-data'
                        responseType: 'blob'
                    }
                ).then(response => {
                        let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
                        url = window.URL.createObjectURL(blob);
                        window.open(url);
                        //var file = response.data;
                        //window.saveAs(file, 'prediction.pdf');
                        /*
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'file.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();*/
                    }
                ).catch(error => this.uploadError=error);
            },
        },
        apollo: {
            prediction: {
                query: gql`
                    query($skladId: ID!, $agentId: ID!){
                        prediction(skladId: $skladId, agentId: $agentId) {
                            agents{
                                id name
                                products{
                                    id nomer name balance expenseWeek week month twomonth incomeCena incomeDate
                                    inbox
                                }
                            }
                        }
                        sklad(id: $skladId) {id name}
                    }`,
                variables() {
                    return {
                        skladId: this.sklad_id,
                        agentId: this.agent_id || -1,
                    }
                },
                result(data) {
                    this.values={};
                    this.sklad=data.data.sklad;
                    let p=data.data.prediction.agents[0].products;
                    if (this.type=='week'){
                        for (let i of p){
                            this.values[""+i.id]= i.inbox>1?(i.week/i.inbox):i.week;
                        }
                    } else if (this.type=='month'){
                       for (let i of p){
                           this.values[""+i.id] = i.inbox>1?(i.month/i.inbox):i.month;
                       }
                    } else if (this.type=='2month'){
                       for (let i of p){
                           this.values[""+i.id] = i.inbox>1?(i.twomonth/i.inbox):i.twomonth;
                       }
                    }
                },
                error(error) {
                    this.error = JSON.stringify(error.message);
                },
            },
        }
	}
</script>
query{

}

<style lang="scss" scoped>
    h2 {
        font-weight: bold;
        font-size: 108%;
    }
    table.products{
        td,th {
            padding: 5px;
            vertical-align: middle;
        }
        th {
            font-size: 85%;
            background: #076198;
            color: #fff;
            vertical-align: middle;
        }
        td:nth-child(1){
            text-align: right;
        }
        input {
            width: 70px;
            text-align: right;
            vertical-align: baseline;
        }
    }
</style>

