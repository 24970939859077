<template>
    <div class="container">
        <section class="section" v-if="phase!='submited'">
            <h1 class="title">Подтверждение заказа хлебобулочных изделий</h1>

            <table class="report striped confirm">
                <thead>
                    <th>#</th>
                    <th>Наименование</th>
                    <th>Цена</th>
                    <th>Кол-во</th>
                </thead>
                <tr v-for="(i,idx) in notempty" :key="idx">
                    <td>
                        {{idx+1}}
                    </td>
                    <td>
                        {{i.name}}
                    </td>
                    <td>
                        {{i.price| currency}}
                    </td>
                    <td>
                        {{i.quantity}}
                    </td>
                </tr>
            </table>

            <div style="margin-top: 30px;">
                <!--div class="field">
                    <div class="select agent" v-if="agents">
                        <select v-model="agent_id">
                            <option :value="agent.id" v-for="(agent, index) in agents" :selected="index === 0 ? 'selected' : false" :key="index">
                                {{agent.name}}
                            </option>
                        </select>
                    </div>
                </div-->

                <div class="field">
                    <v-select :options="[{code:null, label: '-'}].concat(agents)" v-if="agents" v-model="agent"></v-select>
                </div>

                <div class="notification is-warning" v-if="breadCount && breadCount.count">
                    <div class="icon-text">
                        <font-awesome-icon icon="exclamation-triangle" style="color: #fff; font-size: 200%; margin-right: 20px;"/>
                        <span>
                            У вас уже была заявка на текущую дату. При повторной подаче заявки, предыдущая заявка будет отменена.
                        </span>
                    </div>
                </div>


                <div class="field" style="margin-top: 20px;">
                    <button class="button is-primary" @click="submit">
                        Отправить заявку
                    </button>
                    <router-link to="/fast" class="button" style="margin-left: 10px;">Отменить заказ</router-link>
                </div>
            </div>
        </section>
        <TitleWithCheck v-if="phase=='submited'" style="margin-top: 2rem;">
            Заказ оформлен
        </TitleWithCheck>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import TitleWithCheck from '../TitleWithCheck.vue'
    //import TuListGrid from './TuListGrid.vue'
    //import Groups from './Groups.vue'
    //import EditItem from '@/components/EditItem.vue'
    import {format} from 'date-fns'
    import CartMixin from './CartMixin'

    export default {
        components: {
            //TuListGrid, //EditItem,
            TitleWithCheck,
        },
        props: {
            user: Object,
            category: String,
            showmissing: String,
            ppage: String,
            q: String,
        },
        mixins: [CartMixin],
        data: function(){
            return {
                phase: 'fast-order',
                itemModal: false,
                bread: null,
                item: null,
                error: null,
                page: 1,
                agent: null,
            }
        },
        computed: {
            notempty() {
                let tempRecipes = this.bread
                if (!tempRecipes)
                    return;

                tempRecipes = tempRecipes.filter((item) => {
                    return item.quantity>0;
                })
                return tempRecipes;
            }
        },
        methods: {
            user_fetched(){
            },
            getCartName(){
                return 'SECONDARY-CART-ID';
            },
            push(){
                this.$router.push(
                    {
                        name: 'store',
                        query: {
                            category: this.branch_id,
                            showmissing: !this.availability,
                            page: this.page,
                            q: this.search_debounced,
                        }
                    });
            },
            tu_refetch(){
                //this.tu.count=null;
                //this.tu.items.splice(0);
                //this.$apollo.queries.tu.refetch();
            },
            submit(){
                let clientId=null;
                if (process.env.NODE_ENV=='production'){
                    window.ym(83894410, 'getClientID', function(cID) {clientId = cID;});                    
                    ym(83894410,'reachGoal','order-bread'); // eslint-disable-line
                } else {
                    clientId = process.env.NODE_ENV
                }

                this.$apollo.mutate({
                    mutation: gql`
                        mutation($txt: String!, $date: String!, $agentId: ID!, $clientId: String) {
                            order(txt: $txt, date: $date, agentId: $agentId, 
                            cartname: "SECONDARY-CART-ID", clientId: $clientId, orderType: "bread") { ok }
                        }`,
                    variables: {
                        txt: 'Хлеб',
                        date: format((new Date()).setDate((new Date()).getDate() + 1),'dd-MM-yyyy'),
                        agentId: this.agent.code,
                        clientId: clientId,
                    },
                }).then(data => { // eslint-disable-line no-unused-vars
                    //console.log(this.$apollo);
                    this.sended=true;
                    this.phase='submited';
                    this.error=null;
                }).catch(error => {
                    //card.add2cart_finished();
                    //console.log(error);
                    this.error=error;
                });
            },
        },
        apollo: {
            breadCount: {
                query: gql`
                    query($date: Date!, $agentId: ID!) {
                        breadCount(date: $date, agentId: $agentId) {
                            count
                        }
                    }`,
                variables() {
                    return {
                        date: format((new Date()).setDate((new Date()).getDate() + 1),'yyyy-MM-dd'),
                        agentId: this.agent.code,
                    }
                },
                skip(){
                    return !this.agent || !this.agent.code;
                },
                error(error) {
                    this.$emit('error', error);
                },
            },
            bread: {
                query: gql`
                    query {
                        bread {
                            id name goodsId quantity price balance isWeight inbox quantityType img
                            images {id img}
                        }
                    }`,
                /*debounce: 500,
                variables() {
                    return {
                    }
                },
                result(){
                    //lastimg.scrollIntoView({behavior: "smooth"});
                    window.scrollTo(
                        {
                            top: 56,
                            behavior: "smooth"
                        }
                    );
                },*/
                error(error) {
                    this.$emit('error', error);
                },
            },
            agents: {
                query: gql`query{agents {code:id label:name}}`,
                result(data) {
                    if (data && data.data && data.data.agents && data.data.agents.length){
                        this.agent = data.data.agents[0];
                    }
                },
                error(error) {
                    this.$emit('error', error);
                },
                //skip(){
                //    return !this.user;
                //},
            },
        },
    }
</script>

<style lang="scss" scoped>
    table.confirm {
        td:nth-child(3), td:nth-child(4){
            text-align: right;
        }
    }
</style>
