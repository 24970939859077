<template>
    <div class="container" v-if="user && !user.isAnonymous">
        <h1 class="title">Накладная #{{docin}} склада "Не отгруженно"</h1>
        <div class="loading" v-if="$apollo.queries.notShippedDocument.loading"></div>
        <section class="section" v-if="notShippedDocument">
            <article v-if="phase=='document'">
                <table class="report striped">
                    <tr>
                        <th>#</th><th>Наименование</th><th>Кол-во</th><th>Упак.</th><th>Цена</th><th>Сумма</th>
                    </tr>
                    <tr v-for="(i, idx) in notShippedDocument" :class="{'crossed-out': i.checked}" :key="i.id">
                        <td>{{idx+1}}</td>
                        <td @click.stop="click(i)">{{i.name}}</td>
                        <td @click.stop="click(i)" class="currency">{{i.cnt}}</td>
                        <td @click.stop="click(i)" class="currency">{{i.inbox}}</td>
                        <td @click.stop="click(i)" class="currency">{{i.price|currency}}</td>
                        <td @click.stop="click(i)" class="currency">{{i.amount|currency}}</td>
                    </tr>
                </table>
                <div class="field">
                    <div class="control">
                        <button @click="submit" :disabled="sending || check()" class="button is-primary" type="button"
                            :class="{'is-loading': sending}"
                            >
                            Подтвердить
                        </button>
                    </div>
                </div>
            </article>
            <TitleWithCheck style="margin-top: 2rem;" v-if="phase!=='submited'">
                <p class="title-text">Накладная перенесена на склад 1</p>
                <p>
                    <router-link to="/reports/notshipped/">Вернуться к списку</router-link>
                </p>   
            </TitleWithCheck>
        </section>
    </div>
</template>

<script>
    import gql from "graphql-tag"
    import TitleWithCheck from '../TitleWithCheck.vue'

    export default {
        props: {
            user: Object, 
            docin: String,
        },
        components: {
            TitleWithCheck,
        },
        methods: {
            submit(){
                this.sending = true;
                this.$apollo.mutate({
                    mutation: gql`mutation($docin: Int!) {
                        moveToSklad1(docin: $docin) { ok }
                    }`,
                    variables: {
                        "docin": this.docin,
                    },
                }).then(data => {
                    if (process.env.NODE_ENV=='production')
                        window.ym(83894410,'reachGoal','confirm-report-notshipped');
                    this.sending = false;
                    if (data.data.moveToSklad1.ok==true){
                        this.phase='submited';
                    }
                }).catch(error => {
                    this.sending = false;
                    this.error=error;
                });
            },
            click(i){
                if (!Object.prototype.hasOwnProperty.call(i, 'checked') || !i.checked)
                    this.$set(i, 'checked', true)
                else
                    this.$set(i, 'checked', false)
            },
            check(){
                return this.notShippedDocument.find((element) => {
                    if (!Object.prototype.hasOwnProperty.call(element, 'checked'))
                        return true;
                    else if (element.checked==false)
                        return true;
                    return false;
                });
            }
        },
        data(){
            return {
                'phase': 'document',
                'sending': false,
            }
        },
        apollo: {
            notShippedDocument: {
                query: gql`
                    query ($docin: Int!){
                       	notShippedDocument(docid: $docin){
                            cnt amount name price inbox
                        }
                    }`,
                variables() {
                    return {
                        docin: this.docin,
                    }
                },
                error(error) {
                    this.$emit('error', error);
                },
            },
        }
	}
</script>

<style lang="scss" scoped>
    .report{
        tr {
            cursor: pointer;
            td{
                padding: 10px;
            }
        }
        tr.crossed-out{
            text-decoration: line-through;
            font-weight: bold;
        }
    }
</style>

